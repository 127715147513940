<template>
  <div class="">
    <div class="news_banner">
      <img :src="banner[0].bannerImg" alt="" width="100%" />
    </div>
    <el-row :gutter="20" class="index" type="flex">
      <el-col :span="16" :offset="0">
        <div class="hebing">
          <div class="aa1-3"><!-- 应用场景 -->{{$t('applicationDetail.Applications')}}</div>
		  <div style="position: relative;">
			  <img :src="list.img" alt="" width="100%" />
			  <div class="yy1">
			    <!-- <img src="../assets/images/znsj.png" alt="" /> -->
			    {{ list.name }}
			  </div>
		  </div>
          
          <div class="yy3" v-html="list.content"></div>
          <!-- <div class="yy2">
            可应用于智能手机前置和后置摄像头，提高成像质量，降低系统成本:
          </div>
          <div class="yy3">
            1、事件模式下，消除大量图像数据冗余，降低手机算力消耗，减少系统延时与功耗，提升算法精度与实时性，实现更优质的自动对焦、人像提取、特定物体优化等功能;<br />
            2、融合模式下，事件流与图像数据融合，增强手机图像质量，提升暗光拍摄性能，实现优质、低成本的高速视频拍摄、HDR等功能。
          </div> -->
        </div>
        <div style="height: 55px"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  metaInfo: {
    // title: "应用详情",
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "我是keyWords关键字",
      },
      {
        name: "description",
        content: "我是description描述",
      },
    ],
    link: [
      {
        // set link
        rel: "asstes",
        href: "www.baidu.com",
      },
    ],
  },
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      banner: [
        {
          bannerImg: "",
        },
      ],
      list: "",
    };
  },
  // 计算属性
  computed: {
	  language(){
	  		  return this.$store.state.Language
	  },
	  routeQ(){
		  return this.$route.query.username
	  }
  },
  // 侦听器
  watch: {
	  language(newVal,oldVal){
          window.document.title = this.$t('application.title')
	  		  this.getList(this.$route.query.username)
	  },
	  routeQ(newValue,oldVal){
		  this.getList(newValue)
	  }
  },
  // 组件方法
  methods: {
    getBanner() {
      this.$http.get("/appBanner/getBannerList?spaceId=16").then((res) => {
        this.banner = res.data;
        console.log(this.banner);
      });
    },
  	getList(code) {
  	  let language = this.$store.state.Language
  	  this.$http.get("application/list?language="+ language+"&code="+code).then((res) => {
  	    this.list = res.rows[0];
  	  });
  	},
  },
  // 以下是生命周期钩子   注：没用到的钩子请自行删除
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    window.document.title = this.$t('application.title')
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {
    this.getBanner();
	this.getList(this.$route.query.username)
    console.log(this.list);
  },
};
</script>

<style scoped lang="scss">
.news_banner {
  // background: url(../assets/images/cptou.jpg) no-repeat;
  // background-size: 100% 100%;
  // height: 100%;
  // display: flex;
  // justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
  p {
    padding-left: 100px;
  }
}

.index {
  flex-direction: column;
  justify-self: center;
  align-items: center;
}
.hebing {
  text-align: left;
}
.aa1-3 {
  text-align: center;
  font-size: 30px;
  color: rgb(0, 0, 0);
  margin-top: 50px;
  margin-bottom: 45px;
  font-family: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
}
.aa1-1-3-4 {
  color: rgb(147, 149, 148);
  margin-bottom: 50px;
  margin-top: 8px;
}
.chufa {
  // background-color: aqua;
  margin: 3%;
  margin-bottom: 28px;
}
.bs0 {
  position: relative;
}

.zzc {
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.bs1 {
  position: relative;
  top: -4px;
  line-height: 50px;
  height: 50px;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  transition: 0.5s;
}

.chufa:hover .bs1 {
  color: rgb(255, 255, 255);
  background-color: rgb(35, 80, 199);
  transition: 0.5s;
}

.chufa-1:hover .zzc {
  background-color: rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}

.yy1 {
// width: 250px;
	position: absolute;
	line-height: initial;
	left: 0px;
	bottom: 0px;
  width: auto;
  display: inline-block;
  padding: 0px 20px;
  height: 80px;
  // font-size: 32px;
  color: rgb(255, 255, 255);
  line-height: 80px;
  text-align: center;
  // margin-top: 50px;
  // margin-bottom: 30px;
  margin-bottom: 5px;
  // padding-left: 20px;
  background-color: rgb(35, 80, 199);
  // border-left: 8px solid rgb(35, 80, 199);
  vertical-align: middle;
  img {
    position: relative;
    top: -8px;
    height: 50px;
    vertical-align: middle;
    margin-right: 20px;
  }
}
.yy2 {
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin-top: 30px;
  // padding-left: 20px;
  // border-left: 8px solid rgb(35, 80, 199);
}
.yy3 {
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin-top: 10px;
  line-height: 35px;
}

@media screen and (max-width: 1024px) {
  .yy1 {
    // width: 200px;
    height: 55px;
    font-size: 18px;
    color: rgb(255, 255, 255);
    // line-height: 55px;
	line-height: initial;
    text-align: center;
    // margin-top: 50px;
    margin-bottom: 5px;
    // padding-left: 20px;
    background-color: rgb(35, 80, 199);
    // border-left: 8px solid rgb(35, 80, 199);
    vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
    img {
      position: relative;
      top: -8px;
      height: 40px;
      vertical-align: middle;
      margin-right: 20px;
    }
  }
}
</style>
